{
  "name": "camvision",
  "version": "1.37.0-alpha.1.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "debug": "ng serve --configuration=staging ",
    "build": "ng build --configuration production && npm run edit-ngsw",
    "dev": "ng build --configuration dev && npm run edit-ngsw",
    "stage": "ng build --configuration=staging && npm run edit-ngsw",
    "lint": "ng lint",
    "lint-fix": "ng lint --fix --project camvision",
    "stylelint": "stylelint \"src/**/*.scss\"",
    "gen-api": "api-client-generator -s ../swagger-ui.yaml -Co src/api",
    "edit-ngsw": "replace \"onFetch\\(event\\) \\{\" \"onFetch(event) { return;\" dist/camvision/ngsw-worker.js",
    "prettier": "prettier ./src --write"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/material": "^18.2.8",
    "@angular/material-moment-adapter": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@angular/service-worker": "^18.2.8",
    "@ngrx/effects": "^18.1.0",
    "@ngrx/entity": "^18.1.0",
    "@ngrx/operators": "^18.1.0",
    "@ngrx/store": "^18.1.0",
    "@ngrx/store-devtools": "^18.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/debug": "^4.1.12",
    "@types/mdast": "^4.0.4",
    "@types/unist": "^3.0.3",
    "@videogular/ngx-videogular": "file:./libs/videogular-ngx-videogular-0.9.2.tgz",
    "amazon-cognito-auth-js": "^1.3.3",
    "core-js": "^3.38.1",
    "hammerjs": "^2.0.8",
    "hls.js": "^1.5.16",
    "moment": "^2.30.1",
    "ngx-markdown": "^18.1.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.7.0"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/build": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@types/amazon-cognito-auth-js": "^1.3.4",
    "@types/hammerjs": "^2.0.46",
    "@types/node": "^22.7.5",
    "@typescript-eslint/eslint-plugin": "8.8.0",
    "@typescript-eslint/parser": "^8.8.1",
    "api-client-generator": "^5.0.0-beta.6",
    "eslint": "^9.12.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-jsdoc": "^50.3.1",
    "eslint-plugin-prefer-arrow": "latest",
    "prettier": "^3.3.3",
    "replace": "^1.2.2",
    "stylelint": "^16.9.0",
    "ts-node": "^10.9.2",
    "typescript": "~5.5.4"
  },
  "browserslist": [
    "> 0.5%",
    "last 2 versions",
    "safari >= 12",
    "iOS >= 12",
    "Firefox ESR",
    "not dead"
  ]
}
